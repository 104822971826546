export class User {
    FirstName: string;
    LastName: string;
    Email: string;
    UserID: string;
    Country: string;
    Roles: string;
    LastLoginDate: Date;
    IsAdmin: boolean;
}

export class CurrentUserRole {
    IsAdmin: boolean = false;
    IsCustomizer: boolean = false;
    IsTranslator: boolean = false;
    IsApprover: boolean = false;
    IsViewer: boolean = false;
    IsHome: boolean = true;
    IsPublisher: boolean = false;
    IsLocked: boolean = false;
    IsLocalContentAdmin: boolean = false;
}