import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CurrentUserDetails } from '../models/CurrentUserDetails';
import { Observable } from 'rxjs/Observable';
import { environment } from './../../../environments/environment';
import { Role } from '../models/SetSelection';
import { CurrentUserRole } from '../models/user';
import { Store } from '@ngrx/store';
import { NgrxService } from './ngrx.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  loggedUser: CurrentUserDetails;
  LocalRole: Role[];
  currentUserRole: CurrentUserRole;
  customizationBack = false;
  noCountry = false;
  GUID: any;
  BackClicked = false;
  isAlldataLoaded = false;

  constructor(private http: HttpClient,private ngrxService:NgrxService) { }

  getApiURL(): string {
    let urlString: string = environment.APIUrl;
    return urlString.slice(urlString.length - 1) != '/' ? urlString += '/' : urlString;
  }
  getSetVersionID(): Observable<any> {
    let preferenceDetails;
    let setVersionID;
    let ngrxstoreVal:any;
    ngrxstoreVal=this.ngrxService.getNgrxStoreVal();
    if (ngrxstoreVal.Prefernce !== undefined && ngrxstoreVal.Prefernce != null && ngrxstoreVal.Prefernce !== '') {
        preferenceDetails = JSON.parse(ngrxstoreVal.Prefernce);
        setVersionID = preferenceDetails.setID;
    } else {
      setVersionID = 0;
    }
    return setVersionID;
}
  userRoles(): CurrentUserRole {
    this.currentUserRole = new CurrentUserRole();
    let message;
    let ngrxstoreVal:any;
    ngrxstoreVal=this.ngrxService.getNgrxStoreVal();
    const roles = ngrxstoreVal.Roles;
    if (ngrxstoreVal.Prefernce) {
      message = JSON.parse(ngrxstoreVal.Prefernce);
    }
    var tempRoles:any;
    if(ngrxstoreVal.Roles){
      tempRoles = JSON.parse(roles);
    }else{
      tempRoles=null
    }
    if (tempRoles != null) {
      for (const i of tempRoles) {
        if (i.Name == 'Admin') {
          this.currentUserRole.IsAdmin = true;
        } else if (i.Name == 'Approver') {
          this.currentUserRole.IsApprover = true;
        } else if (i.Name == 'Customizer') {
          this.currentUserRole.IsCustomizer = true;
        } else if (i.Name == 'Translator') {
          this.currentUserRole.IsTranslator = true;
        } else if (i.Name == 'Viewer') {
          this.currentUserRole.IsViewer = true;
        } else if (i.Name == 'Publisher') {
        
            this.currentUserRole.IsPublisher = true;
          
        } else if (i.Name == 'Locked') {
          this.currentUserRole.IsLocked = true;
        } else if (i.Name == 'LocalContentAdmin') {
          this.currentUserRole.IsLocalContentAdmin = true;
        }
      }
    }
    return this.currentUserRole;
  }

  static newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  generateGUID(): Observable<any> {
    let id: any;
    for (let i = 0; i < 100; i++) {
      id = CommonService.newGuid();
    }
    return id;
  }

}

