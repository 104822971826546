// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breadcrumb {
    width: 100%;
    z-index: 1;
    color: #fff;
    float: left;
}

.breadcrumb-container {
    font-size: 13px;
}

.search-button, .settings-button {
    background: none;
    border: none;
    outline: none;
}

.right-buttons i {
    color: var(--theme-color);
    font-size: 16px;
    cursor: pointer;
}

.right-buttons {
    float: right;
    top: -2px;
    position: relative;
    color: #000000;
}

.right-buttons b{
    font-size: 15px;
    color: #005eb8;
}

.right-buttons i{
    color: #333;
    font-size: 16px;
    cursor: pointer;
    position: relative;
    top: 1px;
    margin-right: 5px;
}

.bread_custom{
    padding: 1px;
    background: #FFF;
    MARGIN: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
