import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptor } from './http-error.interceptor';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers:[{
    provide:ErrorHandler,useClass:GlobalErrorHandlerService
  },{
    provide:HTTP_INTERCEPTORS,useClass:HttpErrorInterceptor,multi: true 
  }]
})
export class ErrorHandlingModule { }
