// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirm-wrapper {
    top: 40px;
    left: calc((100vw - (350px))/2);
    background: #fff;
    z-index: 999;
    width: 390px;
    padding: 20px 0px 0px 0px;
    box-shadow: 0px 0px 1px 1px #d4d4d4;
    transition: all .2s ease-in;
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease-in;
    transition-delay: initial;
    -webkit-transition: all .2s ease-in;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -ms-transition: all .2s ease-in;
    position: fixed;
}

.alert-wrapper {
    transform: scale(0);
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform: scale(0);
    -ms-transform: scale(0);
    position: fixed;
    visibility: hidden;
}

.active-alert {
    transform: scale(1) !important;
    -webkit-transform: scale(1) !important;
    -moz-transform: scale(1) !important;
    -o-transform: scale(1) !important;
    -ms-transform: scale(1) !important;
    position: fixed;
    visibility: visible;
}

.confirm-buttons button {
    color: #fff;
    width: 50%;
    float: left;
    border: none;
    padding: 12px 0px;
    font-size: 15px;
    font-family: Calibri;
    font-weight: 500;
    text-transform: uppercase;
}

.btn-cancel {
    background: #999999;
}

.btn-proceed {
    background: #0F4D97;
}

.confirm-icon {
    color: #ffc000;
    font-size: 33px;
    position: relative;
    top: 19px;
    left: 31px;
    float: left;
}

.confirm-wrapper p {
    color: var(--header-color);
    font-size: 15px;
    float: left;
    width: 290px;
    left: 50px;
    position: relative;
    text-align: left;
    word-break: break-word;
}

.confirm-buttons button {
    width: 50%;
    float: left;
    border: none;
    padding: 12px 0px;
    cursor: pointer;
}

.confirm-content {
    position: relative;
    min-height: 90px;
}

.alert-wrapper {
    transform: scale(0);
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform: scale(0);
    -ms-transform: scale(0);
    position: fixed;
    visibility: hidden;
}

.active-alert {
    transform: scale(1) !important;
    -webkit-transform: scale(1) !important;
    -moz-transform: scale(1) !important;
    -o-transform: scale(1) !important;
    -ms-transform: scale(1) !important;
    position: fixed;
    visibility: visible;
}

.confirm-box-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: grey;
    z-index: 99;
    opacity: .3;
    visibility: hidden;
}

.active-overlay {
    visibility: visible;
}

.confirmdialogfooter {
    float: left;
    width: 100%;
    text-align: right;
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 0 15px;
    box-sizing: border-box;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
