import { HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse, HttpInterceptor } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
//   import { ErrorDialogService } from "../../shared/errors/error-dialog.service";
//   import { LoadingDialogService } from "../../shared/loading/loading-dialog.service";
import { Injectable } from "@angular/core";
import { ApplicationInsightsService } from "../KMLWeb_Shared/services/application-insights.service";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private loggingService: ApplicationInsightsService

  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';

          if (error.error instanceof ErrorEvent) {
            // client-side error
            this.loggingService.logException(error)
            errorMessage = `Error: ${error.error.message}`;
          } else {
            // server-side error
            this.loggingService.logException(error)
            if (error.error) {
              errorMessage = `${error.error.message}`;
            } else {
              errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
            }
          }
          //(errorMessage);
          return throwError(errorMessage);
        })
      )
  }
}