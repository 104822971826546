// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.divStyle {
  background-image: url('Page_UnderConstruction.2cb63e7487db23dc.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: initial;
  height: 800px;
  background-color: gray;
}

.labelDisplay {
  padding-left: 105px;
  text-align: center;
  vertical-align: middle;
  padding-top: 250px;
  font-weight: bold;
  font-size: 40px;
}

.inputBox {
  padding-left: 15px;
}

.SearchBoxResult {
  line-height: 33px;
  padding: 0px 5px 0px 6px;
  border: 0.5px solid #ccc;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
