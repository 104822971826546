import { createAction } from '@ngrx/store';
export const setgraphAccessToken = createAction('[Counter Component] SetgraphAccessToken', (value: any) => ({ payload: { value } }));
export const setCurrentRoute = createAction('[Counter Component] SetCurrentRoute', (value: any) => ({ payload: { value } }));
export const setcurrentUser = createAction('[Counter Component] SetcurrentUser', (value: any) => ({ payload: { value } }));
export const setmsalidtoken = createAction('[Counter Component] Setmsalidtoken', (value: any) => ({ payload: { value } }));
export const setuserAssignRole = createAction('[Counter Component] SetuserAssignRole', (value: any) => ({ payload: { value } }));
export const setmasterroles = createAction('[Counter Component] Setmasterroles', (value: any) => ({ payload: { value } }));

export const increment = createAction('[Counter Component] Increment', (value: any) => ({ payload: { value } }));
export const decrement = createAction('[Counter Component] Decrement');
export const reset = createAction('[Counter Component] Reset');

export const setCKDStructure = createAction('[Counter Component] setCKDStructure', (value: any) => ({ payload: { value } }));
export const setCVV = createAction('[Counter Component] setCVV', (value: any) => ({ payload: { value } }));
export const setCVVForFilter = createAction('[Counter Component] setCVVForFilter', (value: any) => ({ payload: { value } }));
export const setCVVForFilterCount = createAction('[Counter Component] setCVVForFilterCount', (value: any) => ({ payload: { value } }));
export const setCKDContentData = createAction('[Counter Component] setCKDContentData', (value: any) => ({ payload: { value } }));
export const setCVVForTaxonomySync = createAction('[Counter Component] setCVVForTaxonomySync', (value: any) => ({ payload: { value} }));
export const setStandardCurrentData = createAction('[Counter Component] SetStandardCurrentData', (value: any) => ({ payload: { value } }));
export const setSelectedVersionYear = createAction('[Counter Component] setSelectedVersionYear', (value: any) => ({ payload: { value } }));