import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-unsupported',
  templateUrl: './unsupported.component.html',
  styleUrls: ['./unsupported.component.scss']
})
export class UnsupportedComponent implements OnInit {
  userPermission: boolean = true;
  unAuthorizedUserMessage: string;
  constructor() {
  }

  

  ngOnInit() {
    sessionStorage.clear();
    localStorage.clear();
    
  }

}
