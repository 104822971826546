import { Component, HostListener } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { BnNgIdleService } from 'bn-ng-idle';
import { ADAuthService } from './KMLWeb_Shared/services/adauth.service';
import { Observable } from 'rxjs-compat';
import { DatePipe, PlatformLocation } from '@angular/common';
import { AdminServiceService } from './KMLWeb_Main/admin/admin.service';
import { environment } from '../environments/environment';
import { Store } from '@ngrx/store';
import { setmsalidtoken } from './store/counter.actions';
import { NgrxService } from './KMLWeb_Shared/services/ngrx.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @HostListener('window:load', ['$event'])
  unloadHandler(event) {
    if (sessionStorage.getItem('msal.idtoken')) {
      if (sessionStorage.getItem('msal.idtoken') == '' || sessionStorage.getItem('msal.idtoken') == null) {
        localStorage.clear();
      }
    } 
    //else {
     // localStorage.clear();
    //}
    //this.adAuthService.logout();
  }
  userRoles: any[] = [];
  userAssignRole: any[] = [];
  appDisable = false;
  constructor(
    private location: PlatformLocation,
    private router: Router,
    public route: ActivatedRoute,
    private bgdIdle: BnNgIdleService,
    private adAuthService: ADAuthService,
    private _datePipe: DatePipe,
    private adminservice: AdminServiceService,
    private ngrxService: NgrxService
  ) {
    location.onPopState(() => {
      if (window.location.hash != '#/unsupported') {
        setTimeout(() => {
          if (sessionStorage.getItem('msal.idtoken')) {
            if (sessionStorage.getItem('msal.idtoken') == '' || sessionStorage.getItem('msal.idtoken') == null) {
              localStorage.clear();
              this.router.navigate(['/unauthorized'])
            }
          } else {
            localStorage.clear();
            this.router.navigate(['/unauthorized'])
          }
        }, 300);
      }
    })
    this.bgdIdle.startWatching(environment.idle_timer).subscribe((res) => {
      if (res) {
        if (this.ifLoggedIn()) {
          const navigationExtras: NavigationExtras = {
            queryParams: {
              errorMessage: environment.sessionTimoutMessage,
              userUnauthorizationMsgHide: true
            }
          };
          this.router.navigate(['/unauthorized'], { queryParams: navigationExtras.queryParams, relativeTo: this.route });
        }
      }
    });

    let interval = Observable.interval(environment.refreshtoken_timer).takeWhile(() => true).subscribe(() => {
      this.refreshToken()
    });
  }

  ngOnInit() {
    this.detectBrowser();
  }

  ifLoggedIn() {
    let adToken: any;
    let ngrxstoreVal: any;
    ngrxstoreVal = this.ngrxService.getNgrxStoreVal();
    adToken = ngrxstoreVal.msalidtoken;
    return (adToken != undefined && adToken != "") ||
     window.location.href.indexOf('unauthorized') > -1 ||
     window.location.href.indexOf('unsupported') > -1;  
  }

  detectBrowser()
  {
    const agent = window.navigator.userAgent.toLowerCase()

    var old_ie = agent.indexOf('msie');

    //This is for IE 11 and above
    var new_ie = agent.indexOf('trident/');
 
    if(((old_ie > -1) || (new_ie > -1)) || (agent.indexOf('opr') >-1) && !!(<any>window).opr)
    {
      this.router.navigate(['/unsupported']);
    }
  }

  refreshToken() {
    // if (tokenVal != '' && this.adAuthService.tokenExpired(tokenVal)) {
      this.adAuthService.generateNewToken().subscribe(data => {
        this.ngrxService.setmsalidtoken(data);
      });
    // }
  }

  getCurrentUserwithRole() {
    this.adminservice.GetCurrentUserwithRole().subscribe(Response => {
      if (Response && Response != null) {
        this.userRoles = Response.userRoles;
        this.userRoles.forEach(value => {
          this.userAssignRole.push(value.role.name);
        });
        this.ngrxService.setuserAssignRole(JSON.stringify(this.userAssignRole))
        if (this.userAssignRole.length > 0) {
          if (this.userAssignRole.indexOf("BusinessAdmin") > -1) {
            console.log("User have all permission.");
            this.appDisable = false;
          } else if (this.userAssignRole.indexOf("SupportUser") > -1) {
            this.appDisable = false;
          } else {
            this.appDisable = true;
          }
        } else {
          this.router.navigateByUrl("/unauthorized")
        }
      } else {
        this.router.navigateByUrl("/unauthorized")
      }
    })
  }
}
