import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { ApplicationInsightsService } from '../KMLWeb_Shared/services/application-insights.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler {

  constructor(private loggingService:ApplicationInsightsService) { }

  handleError(error: Error){
    if(error instanceof HttpErrorResponse){
      //alert('backend error')
      this.loggingService.logException(error)
      //alert('backend error');
    }else{
      //alert('frontend error')
      this.loggingService.logException(error)
      
      //console.error(error)
    }
  }
}
