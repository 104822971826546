import { state } from '@angular/animations';
import { createReducer, on } from '@ngrx/store';
import { increment, setcurrentUser, setuserAssignRole, setmasterroles, setmsalidtoken, setCurrentRoute, decrement, reset, setgraphAccessToken } from './counter.actions';

export const initialState = {
  graphAccessToken: "",
  CurrentRoute: "",
  currentUser: "",
  msalidtoken: "",
  userAssignRole: "",
  masterroles: ""
}

const _counterReducer = createReducer(
  initialState,
  on(setgraphAccessToken, (state, { payload }) => ({ ...state, graphAccessToken: payload.value })),
  on(setCurrentRoute, (state, { payload }) => ({ ...state, CurrentRoute: payload.value })),
  on(setcurrentUser, (state, { payload }) => ({ ...state, currentUser: payload.value })),
  on(setmsalidtoken, (state, { payload }) => ({ ...state, msalidtoken: payload.value })),
  on(setuserAssignRole, (state, { payload }) => ({ ...state, userAssignRole: payload.value })),
  on(setmasterroles, (state, { payload }) => ({ ...state, masterroles: payload.value })),
  on(reset, (state) => state)
);

export function counterReducer(state, action) {
  return _counterReducer(state, action);
}