import { Component, NgZone, OnInit } from '@angular/core';
import { Alert, AlertType } from '../../../models/Alter';
import { LoaderService } from '../loader.service';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit {

    constructor(private loaderService: LoaderService,
                private ngZone: NgZone) { }
    alert: Alert;
    showAlert: boolean;
    time: number;
    ngOnInit() {

        this.alert = new Alert();
        this.loaderService.subject.subscribe(
            (data) => {
                if (data.message == 'closeAlert')
                    this.closeDiv();
                else {
                    let allowTimeOut = false;
                    if (data.type != 4 && data.type != 5) {
                        allowTimeOut = true;
                    }
                    data.type === 0 ? this.time = 4000 : this.time = 4000;
                    this.alert = new Alert();
                    this.alert.message = data.message;
                    this.alert.type = data.type;
                    this.showAlert = true;

                    if (allowTimeOut) {
                        this.ngZone.runOutsideAngular(() =>

                            setTimeout(() =>
                                this.ngZone.run(() =>
                                    this.showAlert = false
                                ), this.time
                            )
                        );
                    }
                }
            });
    }
    cssClass() {
        if (!this.alert) {
            return;
        }
        switch (this.alert.type) {
            case AlertType.Success:
                return 'alert alert-success';

            case AlertType.ErrorWithoutTimeout:
            case AlertType.Error:
                return 'alert alert-danger';

            case AlertType.Info:
                return 'alert alert-info';

            case AlertType.Warning:
                return 'alert alert-warning';

            case AlertType.WarningSpecial:
                return 'alert alert-warning WarningSpecial';
        }

    }
    closeDiv() {
        this.showAlert = false;
        this.loaderService.displayLoader(false);
    }

}
