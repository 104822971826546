// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footerContiner {
  float: left;
  margin-left: 40px;
  border-top: 1px solid #D7D7D7;
  margin-top: 20px;
  padding: 6px 20px 6px 30px;
}
.footerContiner p {
  color: #333333;
  font-family: Calibri;
  font-size: 11px;
  letter-spacing: 0.06px;
  line-height: 13px;
  margin-bottom: 5px;
}
.footerContiner p a {
  color: #333333;
  text-decoration: underline;
  text-decoration-color: #005EB8;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
