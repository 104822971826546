// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard_cover {
  padding: 15px;
  float: left;
  width: 100%;
}
.dashboard_cover .card-body {
  background-color: #ffffff !important;
  width: 100%;
}

.card-shadow-sm {
  box-shadow: 0 1px 4px 0 #9b9b9b;
}

.card-body:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}
.card-body .dash-img-kat {
  text-align: center;
  margin: 0 auto;
  width: 50px;
  height: 50px;
  background: url('kml-sprite-v6.26b7ccca28df39ea.png');
}
.card-body .dash-img-kat.user-settings {
  background-position: -25px -53px;
}
.card-body .dash-img-kat.user-customize {
  background-position: -25px -554px;
}
.card-body .dash-img-kat.user-reports {
  background-position: -25px -414px;
}
.card-body .dash-img-kat.user-translate {
  background-position: -25px -295px;
}
.card-body .dash-img-kat.user-publish {
  background-position: -25px -705px;
}
.card-body .dash-img-kat.user-review {
  background-position: -25px -842px;
}
.card-body .dash-img-kat.data-processing {
  background-position: -25px -1132px;
}
.card-body .dash-img-kat.content-management {
  background-position: -25px -992px;
}
.card-body h3 {
  font-family: Calibri;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.26px;
  text-align: center;
  color: #333;
}

.col-sm-2.col-md-2.col-lg-2.col-xs-4.pr-0 {
  margin-bottom: 15px;
}

.disableDiv {
  pointer-events: none;
  /* for "disabled" effect */
  opacity: 0.5;
  background: #CCC;
  cursor: not-allowed;
}

@media only screen and (min-width: 1366px) and (max-width: 1500px) {
  .col-sm-2.col-md-2.col-lg-2.col-xs-4 {
    flex: 0 0 20% !important;
    max-width: 20% !important;
  }
}
@media only screen and (min-width: 999px) and (max-width: 1365px) {
  .col-sm-2.col-md-2.col-lg-2.col-xs-4 {
    flex: 0 0 22% !important;
    max-width: 22% !important;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
