import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { NgrxService } from './KMLWeb_Shared/services/ngrx.service';

@Injectable()
export class ConfigService {
  private config: object;
  options: any = {};
  constructor(private ngrxService: NgrxService) {
    this.options.withCredentials = true;
  }

  get(key: any) {
    return this.config[key];
  }
  load() {

  }
}
