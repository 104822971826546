import { Component, OnInit } from '@angular/core';
import { ADAuthService } from '../../../KMLWeb_Shared/services/adauth.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  copyrightYear: number;
  constructor(private auth: ADAuthService) { 
  }
  showFooter = false;
  ngOnInit(): void {
    console.log(1);
    setTimeout(() => {
      this.showFooter = true;
      this.copyrightYear = (new Date()).getFullYear();
    }, 2000);
  }
}
