import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { DashboardComponent } from './KMLWeb_Main/dashboard/dashboard/dashboard.component';
import { UnderConstructionComponent } from './KMLWeb_Shared/components/under-construction/under-construction.component';
import { MsalGuard } from '@azure/msal-angular';
import { UnauthorizedComponent } from './KMLWeb_Shared/components/unauthorized/unauthorized.component';
import {UnsupportedComponent} from './KMLWeb_Shared/components/unsupported/unsupported.component'

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    data: {
      applicationId: 'Dashboard',
      helpId: 'Dashboard',
    },
    canActivate: [MsalGuard]
  },
  {
    path: 'home',
    component: DashboardComponent,
    data: {
      applicationId: 'Dashboard',
      helpId: 'Dashboard',
    },
    canActivate: [MsalGuard]
  },
  {
    path: 'admin',
    loadChildren: () => import('././KMLWeb_Main/admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'datamanagement',
    loadChildren: () => import('././KMLWeb_Main/dataprocessing/dataprocessing.module').then(m => m.DataprocessingModule)
  },
  {
    path: 'contentManagement',
    loadChildren: () => import('././KMLWeb_Main/contentmanagement/contentmanagement.module').then(m => m.ContentmanagementModule)
  },
  {
    path: 'import',
    loadChildren: () => import('././KMLWeb_Main/import/import.module').then(m => m.ImportModule),
    /*data:{
      breadcrumb: 'Data Management',
      breadcrumb_route:'/dataprocessing',
      appId: 'ULC'
    }*/
  },
  {
    path: 'reporting',
    loadChildren: () => import('././KMLWeb_Main/reporting/reporting.module').then(m => m.ReportingModule)
  },
  {
    path: 'construction',
    component: UnderConstructionComponent,
  },

  {
    path: 'unauthorized',
    component: UnauthorizedComponent
  },
  {    
    path: 'unsupported',
    component: UnsupportedComponent
  },
  { path: '**', redirectTo: '/', pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  //  imports: [RouterModule.forRoot(routes, { useHash: true, preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]//,

})
export class AppRoutingModule { }
