// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PaginationWrapper {
  float: left;
  width: 100%;
}
.PaginationWrapper .pagination li {
  margin-right: 10px;
}
.PaginationWrapper .pagination li .page-link {
  padding: 3px 11px;
  border-radius: 4px;
  border-color: #005EB8;
  color: #0863BA;
  font-family: Calibri;
  font-size: 14px;
  letter-spacing: 0;
}
.PaginationWrapper .pagination li.active a {
  color: #fff !important;
}
.PaginationWrapper .pagination li.disabled .page-link {
  border-radius: 4px;
  border-color: #C6C6C6 !important;
  color: #C6C6C6 !important;
}

.disableDiv {
  pointer-events: none;
  /* for "disabled" effect */
  opacity: 0.5;
  background: rgb(121, 118, 118);
  color: rgb(121, 118, 118);
  cursor: not-allowed;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
