import { Component, OnInit, OnDestroy } from '@angular/core';
import { Confirm } from '../../../models/Confirms';
import { LoaderService } from '../loader.service';

@Component({
  selector: 'app1-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent implements OnInit, OnDestroy {
  confirm: Confirm;
  subscription: any;
  constructor(private loaderService: LoaderService) { }

  ngOnInit() {
    this.confirm = new Confirm();
    this.subscription = this.loaderService.getMessage().subscribe(message => {
      this.confirm = message;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
