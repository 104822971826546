import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from "xlsx/xlsx";


const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ExcelService {
  public sheetName: any;
  constructor() { }

  public exportAsExcelFile(json: any[], excelFileName: string, sheet?: string): void {
    if (!Array.isArray(json)){
      json = [json];
    }
    const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    this.sheetName = sheet === undefined ? 'data' : 'Sheet1';
    console.log(myworksheet)
    for (var i in myworksheet) {
      
      console.log(myworksheet[i]);
      if(excelFileName.includes("Push to Production") && myworksheet[i].v=="description"){
        myworksheet[i].v="Reason for failure";
      }
      if (typeof myworksheet[i] != 'object') continue;
      let cell = XLSX.utils.decode_cell(i);
      if (cell.r == 0) { myworksheet[i].s = { font: {bold: 'true'  }, }; }
      }  
    const myworkbook: XLSX.WorkBook = { Sheets: { [this.sheetName]: myworksheet }, SheetNames: [this.sheetName] };  
    const excelBuffer: any = XLSX.write(myworkbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    console.log("in excel save")
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    if (this.sheetName == 'Sheet1') {
      FileSaver.saveAs(data, fileName + '' + EXCEL_EXTENSION);
    }
    else {
      FileSaver.saveAs(data, fileName + '_exported' + EXCEL_EXTENSION);
    }


  }

  public exportAsExcelFileLogicalModel(json: any[], excelFileName: string, jsonh: any[]): void {
    if (!Array.isArray(json)) {
      json = [json];
    }
    if (!Array.isArray(jsonh)) {
      jsonh = [jsonh];
    }

    //to create a new workbook and then add the header
    const wb = XLSX.utils.book_new();
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, [jsonh]);

    //Starting in the second row to avoid overriding and skipping headers
    XLSX.utils.sheet_add_json(ws, json, { origin: 'A2', skipHeader: true });

    XLSX.utils.book_append_sheet(wb, ws, 'data');

    XLSX.writeFile(wb, excelFileName + EXCEL_EXTENSION);

  }
}