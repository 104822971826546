// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `jw-modal {
  display: none;
  border-radius: 5px;
}
jw-modal .jw-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
}
jw-modal .jw-modal .jw-modal-body {
  margin: 35px auto;
}
jw-modal .jw-modal-background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  opacity: 0.75;
  z-index: 999;
}

body.jw-modal-open {
  overflow: hidden;
}

.entryViewContiner, .jw-modal-body {
  width: 450px;
  margin: auto;
}

.checkin-text {
  font-size: 20px;
  width: 120%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
