import { Component, OnInit, SecurityContext } from '@angular/core';
import { PreferncemessageService } from '../../services/preferncemessage.service';
import { Subscription } from 'rxjs';
import { headerPrefernce } from '../../models/SetSelection';
import { CurrentUserDetails } from '../../../KMLWeb_Shared/models/CurrentUserDetails';
import { CommonService } from '../../../KMLWeb_Shared/services/common.service';
import { ADAuthService } from '../../services/adauth.service';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { EventEmitterService } from '../../services/event-emitter.service';
import { Store } from '@ngrx/store';
import { NgrxService } from '../../services/ngrx.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app1-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  showSettings: boolean;
  preModalId = 'Prefrence-modal';
  headerPrefrence: headerPrefernce = new headerPrefernce();
  subscription: Subscription;
  IsprefernceVisible = false;
  userSubscription: Subscription;
  loggedUser: CurrentUserDetails = new CurrentUserDetails();

  constructor(
    private messageService: PreferncemessageService,
    public commService: CommonService,
    private router: Router,
    public route: ActivatedRoute,
    private adAuthService: ADAuthService,
    private domSanitizer: DomSanitizer,
    private eventEmitterService: EventEmitterService, private ngrxService: NgrxService) {
    if (this.subscription == undefined) {
      this.subscription = this.eventEmitterService.invokeSidebar.subscribe(() => {
        this.afterLoad();
      })
    }
    this.subscription = this.messageService.getMessage().subscribe(message => {
      if ('countryName' in message && 'setName' in message && 'localeName' in message) {
        this.headerPrefrence = message;
        this.IsprefernceVisible = true;
        this.afterLoad();
      }
    });
    if (this.userSubscription == undefined) {
      this.userSubscription = this.eventEmitterService.invokeSidebar.subscribe(() => {
        this.afterLoad();
      })
    }
  }

  ngOnInit() {
    if (sessionStorage.getItem('msal.idtoken')) {
      this.afterLoad();
    }
  }

  afterLoad() {
    if (this.headerPrefrence) {
      this.IsprefernceVisible = false;
      let ngrxstoreVal: any;
      ngrxstoreVal = this.ngrxService.getNgrxStoreVal();
      if (!(ngrxstoreVal.currentUser)) {
        this.adAuthService.getCurrentUserInfo();
      } else {
        if ((ngrxstoreVal.currentUser)) {
          this.loggedUser = JSON.parse(ngrxstoreVal.currentUser);
        }
      }
    }
    this.commService.userRoles();
  }

  signout() {
    sessionStorage.clear();
    localStorage.clear();
    console.log(this.domSanitizer.sanitize(SecurityContext.URL, window.location.origin));
    //window.location.href = this.domSanitizer.sanitize(SecurityContext.URL, window.location.origin);
    this.adAuthService.logout();
  }
}
