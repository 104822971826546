export const environment = {
  production: false,
  APPUrl: "https://kml.qa.kpmg.com/",
  APIUrl: "https://kmlapi.qa.kpmg.com/api/",
  ClientID: "69028969-0738-4d95-a928-65d764624812",
  instrumentationKey: "",
  AuthorityURL: "https://login.microsoftonline.com/cf59843c-25a6-4e9e-a632-73d41e867a1f",
  authenticationMessage: "Auth Token expired",
  sessionTimoutMessage: "Session Timed out - Please login",
  idle_timer: 1200,
  refreshtoken_timer: 2000000,
  gridLoadValue: 100,
  handsontableLicenseKey: "3bb31-c3a50-e1115-84e35-43e2a"
};