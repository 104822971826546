import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { Alert, AlertType } from '../../models/Alter';
import { Confirm } from '../../models/Confirms';
import 'rxjs/add/operator/map';

@Injectable()
export class LoaderService {
  public status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  subject = new Subject<Alert>();
  isActive = false;
  confirmSubject = new Subject<any>();

  constructor() { }

  displayLoader(value: boolean) {
    this.isActive = value;
    this.status.next(value);
  }
  getAlert(type: AlertType, message: string) {
    this.subject.next({ type, message });
  }
  success(message: string) {
    this.getAlert(AlertType.Success, message);
  }
  info(message: string) {
    this.getAlert(AlertType.Info, message);
  }

  confirm(message: string, siFn: () => void, noFn: () => void) {
    this.setConfirmation(message, siFn, noFn);
  }
  
  closeAlert() {
    this.subject.next({ type:AlertType.Info, message:'closeAlert' });
  }

  setConfirmation(message: string, siFn: () => void, noFn: () => void) {
    const that = this;
    this.confirmSubject.next({
      text: message,
      siFn() {
        that.confirmSubject.next(new Confirm());
        siFn();
      },
      noFn() {
        that.confirmSubject.next(new Confirm());
        noFn();
      }
    });
  }

  getMessage(): Observable<any> {
    return this.confirmSubject.asObservable();
  }
}
