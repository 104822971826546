import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EventEmitterService {

  invokeSidebar = new EventEmitter();
  constructor() { }

  refreshSidebar(){
    this.invokeSidebar.emit();
  }
}
