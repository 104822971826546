export class CurrentUserDetails {
    FirstName: string;
    LastName: string;
    Alias: string;
    Email: string;
    SID: string;
    IsActive: string;
    IsAdministrator: string;
    ID: any;
}

