import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  setCurrentRoute, setcurrentUser, setgraphAccessToken, setmasterroles, setmsalidtoken, setuserAssignRole,
  setCKDStructure, setCVV, setCVVForFilter, setCVVForFilterCount, setCKDContentData, setCVVForTaxonomySync,
  setStandardCurrentData, setSelectedVersionYear
} from 'src/app/store/counter.actions';

@Injectable({
  providedIn: 'root'
})
export class NgrxService {

  constructor(private store: Store<{ auth_data: any }>, private ckdStore: Store<{ ckd_data: any }>) { }

  getNgrxStoreVal() {
    let ngrxstoreVal: any;
    this.store.select('auth_data').subscribe(
      (value) => {
        ngrxstoreVal = value
      }
    )
    return ngrxstoreVal
  }

  getNgrxCKDStoreVal() {
    let ngrxstoreVal: any;
    this.ckdStore.select('ckd_data').subscribe(
      (value) => {
        ngrxstoreVal = value
      }
    )
    return ngrxstoreVal
  }


  setmsalidtoken(data: any) {
    this.store.dispatch(setmsalidtoken(data))
  }

  setgraphAccessToken(data: any) {
    this.store.dispatch(setgraphAccessToken(data))
  }

  setmasterroles(data: any) {
    this.store.dispatch(setmasterroles(data))
  }

  setCurrentRoute(data: any) {
    this.store.dispatch(setCurrentRoute(data))
  }

  setuserAssignRole(data: any) {
    this.store.dispatch(setuserAssignRole(data))
  }

  setcurrentUser(data: any) {
    this.store.dispatch(setcurrentUser(data))
  }

  setCKDStructure(data: any) {
    this.ckdStore.dispatch(setCKDStructure(data))
  }
  setCVV(data: any) {
    this.ckdStore.dispatch(setCVV(data))
  }
  setCVVForFilter(data: any) {
    this.ckdStore.dispatch(setCVVForFilter(data))
  }
  setCVVForFilterCount(data: any) {
    this.ckdStore.dispatch(setCVVForFilterCount(data))
  }
  setCKDContentData(data: any) {
    this.ckdStore.dispatch(setCKDContentData(data))
  }
  setCVVForTaxonomySync(data:any) {
    this.ckdStore.dispatch(setCVVForTaxonomySync(data))
  }
  setStandardCurrentData(data: any) {
    this.ckdStore.dispatch(setStandardCurrentData(data))
  }
  setSelectedVersionYear(data: any) {
    this.ckdStore.dispatch(setSelectedVersionYear(data))
  }
}
