import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from '../../../KMLWeb_Shared/services/common.service';
import { CurrentUserRole } from 'src/app/KMLWeb_Shared/models/user';
import { PreferncemessageService } from 'src/app/KMLWeb_Shared/services/preferncemessage.service';
import { Router } from '@angular/router';
import { ADAuthService } from '../../../KMLWeb_Shared/services/adauth.service';
import { AdminServiceService } from '../../admin/admin.service';
import { EventEmitterService } from 'src/app/KMLWeb_Shared/services/event-emitter.service';
import { NgrxService } from 'src/app/KMLWeb_Shared/services/ngrx.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  constructor(public commonService: CommonService,
    private messageService: PreferncemessageService,
    private router: Router,
    private auth: ADAuthService,
    private adminservice: AdminServiceService,
    private eventEmitterService: EventEmitterService,
    private ngrxService: NgrxService) { }
  LocalRole: any;
  route: string;
  preModalId = 'Prefrence-modal';
  userRoles: any[] = [];
  userAssignRole: any[] = [];
  appDisable = false;
  showContent = false;
  ngOnInit() {
    let token = sessionStorage.getItem('msal.idtoken');
    this.ngrxService.setmsalidtoken(token);
    this.getCurrentUserwithRole();
  }

  sendMessage(): void {
    let message = { text: this.preModalId, from: "dashboard-load" };
    this.messageService.sendMessage(message);
  }

  updateLastLogin() {
    let ngrxstoreVal: any;
    ngrxstoreVal = this.ngrxService.getNgrxStoreVal();
    if (ngrxstoreVal.userAssignRole == '') {
      this.adminservice.UpdateLastLogin().subscribe(Response => {
      });
    }
  }
  getCurrentUserwithRole() {
    this.adminservice.GetCurrentUserwithRole().subscribe(Response => {
      if (Response && Response != null) {
        this.userRoles = Response.userRoles;
        this.userRoles.forEach(value => {
          this.userAssignRole.push(value.role.name);
        });
        this.updateLastLogin();
        this.ngrxService.setuserAssignRole(JSON.stringify(this.userAssignRole))
        this.eventEmitterService.refreshSidebar();
        if (this.userAssignRole.length > 0) {
          if (this.userAssignRole.indexOf("BusinessAdmin") > -1) {
            console.log("User have all permission.")
            this.appDisable = false;
            this.showContent = true;
          } else if (this.userAssignRole.indexOf("SupportUser") > -1) {
            this.appDisable = false;
            this.showContent = true;
          } else {
            this.appDisable = true;
            this.showContent = true;
          }

        } else {
          this.router.navigateByUrl("/unauthorized")
        }
      } else {
        this.router.navigateByUrl("/unauthorized")
        this.appDisable = true;
      }
    })
  }
}
