import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/internal/operators';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { LoaderService } from '../components/alert-loader/loader.service';
import { environment } from './../../../environments/environment';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router, private route: ActivatedRoute, private loader: LoaderService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((err, caught: Observable<HttpEvent<any>>) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            this.loader.displayLoader(false);
            const navigationExtras: NavigationExtras = {
              queryParams: {
                errorMessage: environment.authenticationMessage,
                userUnauthorizationMsgHide: true
              }
            };
            this.router.navigate(['/unauthorized'], { queryParams: navigationExtras.queryParams, relativeTo: this.route });
            return of(err as any);
          } else if (err instanceof HttpErrorResponse && err.status === 502) {
            const message = 'The request timed out for the current operation.' +
              ' This can happen if you try to create a total of 600 or more relationships at once. Please use smaller sets of operations';
            this.loader.getAlert(2, message); 
            this.loader.displayLoader(false);           
            return of(err as any);
          }
          else if (err instanceof HttpErrorResponse && err.status === 403) {
            const message = 'You are not allowed to perform this action';
            this.loader.getAlert(2, message);
            this.loader.displayLoader(false);
            return of(err as any);
          }
          throw err;
        })
      );
  }
}