export class SetSelectionModel {
    Countries: Countries[];
    Sets: Sets[];
    Locales: Locales[];
    UserPreference: userPreference;
    ID: string;
    UserRoles: UserRoles[];
    IsUserValid: boolean;
    ErrorMessage: string;
    IsAdmin: boolean;
    IsLocked: boolean;
}

export class Countries {
    Name: string;
    SetVersionID: string;
    ID: string;
    Key: string;
}
export class Sets {
    Name: string;
    Description: string;
    ID: string;
}
export class Locales {
    CountryID: string;
    Name: string;
    LocaleKey: string;
    setVersion: string;
    ID: string;
}
export class userPreference {
    Email: string;
    UserID: string;
    CountryID: string;
    SetVersionID: string;
    LocaleID: string;
    IsDefault: boolean;
    CategoryPreference: string;
    ID: string;
}
export class headerPrefernce {
    setName: string;
    countryName: string;
    localeName: string;
}

export class customizePreference {
    setID: string;
    contryID: string;
    localeName: string;
    countryName: string;
    countryKey: string;
    from: string;
}

export class UserRoles {
    UserID: number;
    Country: string;
    CountryID: number;
    RoleID: number;
    Role: Role;
    ID: number;
}

export class Role {
    name: string;
    displayName:string;
    id: number;    
}

