// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.unauthorized-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #012446;
  z-index: 99;
  opacity: 0.8;
}

.unauthorized {
  margin: 0px auto !important;
  border: 0;
  top: 21vh;
  position: relative;
  text-align: center;
  max-width: 440px;
  width: calc(100% - 40px);
  padding: 44px;
  margin-bottom: 28px;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  min-width: 320px;
  min-height: 338px;
  overflow: hidden;
}

.unauthorized img {
  display: block;
  text-align: center;
  margin: 0px auto;
}

.unauthorized .card-header {
  background: #fff;
  color: #bc204b;
  font-size: 37px;
  padding: 10px !important;
  text-transform: uppercase;
}

.unauthorized .btn {
  padding: 6px 50px !important;
  border-radius: 100px;
  font-size: 18px;
  margin-top: 20px;
  color: #fff;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
