import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, PRIMARY_OUTLET, Router } from '@angular/router';
import 'rxjs/add/operator/filter';
import { ConfigService } from '../../../config.service';
interface IBreadcrumb {
  label: string;
  params?: Params;
  url: string;
}

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.css']
})
export class BreadcrumbsComponent implements OnInit {

  public breadcrumbs: IBreadcrumb[];
  userName: string;
  currentCountry: string;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private configService: ConfigService
  ) {
    this.breadcrumbs = [];
  }

  ngOnInit() {
    const ROUTE_DATA_BREADCRUMB: string = 'breadcrumb';
    this.router.events.filter(event => event instanceof NavigationEnd).subscribe(event => {
      const root: ActivatedRoute = this.activatedRoute.root;
      this.breadcrumbs = this.getBreadcrumbs(root);
    });
  }

  private getBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: IBreadcrumb[] = []): IBreadcrumb[] {
    const ROUTE_DATA_BREADCRUMB = 'breadcrumb';
    const ROUTE_DATA_BREADCRUMB_URL = "breadcrumb_route";
    const HOME_PAGE_BREADCRUMB = 'Home';
    const children: ActivatedRoute[] = route.children;
    if (children.length === 0) {
      return breadcrumbs;
    }
    for (const child of children) {
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }
      if (child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB) &&
        child.snapshot.data[ROUTE_DATA_BREADCRUMB] === HOME_PAGE_BREADCRUMB) {
        return breadcrumbs;
      }
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      url += `/${routeURL}`;
      if (child.snapshot.data[ROUTE_DATA_BREADCRUMB]) {
        const breadcrumb: IBreadcrumb = {
          label: child.snapshot.data[ROUTE_DATA_BREADCRUMB],
          params: child.snapshot.params,
          url: child.snapshot.data[ROUTE_DATA_BREADCRUMB_URL] == undefined ? url :child.snapshot.data[ROUTE_DATA_BREADCRUMB_URL] 
        };
        breadcrumbs.push(breadcrumb);
      }
      return this.getBreadcrumbs(child, url, breadcrumbs);
    }
    return breadcrumbs;
  }

  trackByFn(index, item) {
    return index;
  }

  removeRouteTrack() {
    sessionStorage.removeItem('CurrentRoute');
  }

}
