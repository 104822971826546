import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnChanges {
  @Input() totalRecords = 0;
  @Input() recordsPerPage = 0;
  @Output() onPageChange: EventEmitter<number> = new EventEmitter();
  public pages: number[] = [];
  activePage: number;
  leftArrowDisable = false;
  rightArrowDisable = false;
  ngOnChanges(): any {
    this.leftArrowDisable = true;
    const pageCount = this.getPageCount();
    this.activePage = 1;
    this.pages = this.getArrayOfPage(pageCount, this.activePage);
    //this.onPageChange.emit(1);
  }

  private getPageCount(): number {
    let totalPage = 0;
    if (this.totalRecords > 0 && this.recordsPerPage > 0) {
      const pageCount = this.totalRecords / this.recordsPerPage;
      const roundedPageCount = Math.floor(pageCount);
      totalPage = roundedPageCount < pageCount ? roundedPageCount + 1 : roundedPageCount;
    }
    return totalPage;
  }

  private getArrayOfPage(pageCount: number, currentPage: number): number[] {
    ////////////////////*Style 1*///////////////////
    // const pageArray = [];  

    // if (pageCount > 0) {  
    //     for(let i = 1 ; i <= pageCount ; i++) {  
    //       pageArray.push(i);  
    //     }  
    // }  

    // return pageArray; 
    ////////////////////*Style 1*/////////////////// 
    ////////////////////*Style 2*///////////////////
    // const delta = 10
    //   console.log(currentPage);
    //   let range = []
    //   for (let i = Math.max(2, currentPage - delta); i <= Math.min(pageCount - 1, currentPage + delta); i++) {
    //     console.log(i);
    //     range.push(i)
    //   }

    //   if (currentPage - delta > 10) {
    //     range.unshift("...")
    //   }
    //   if (currentPage + delta < pageCount - 1) {
    //     range.push("...")
    //   }

    //   range.unshift(1)
    //   range.push(pageCount)

    //   return range;
    ////////////////////*Style 2*///////////////////

    let delta = 2,
      // left = currentPage == 4 ? currentPage - (delta + 1) : currentPage - delta,
      left = currentPage - delta,
      right = currentPage + delta + 1,
      result = [];

    result = Array.from({ length: pageCount }, (v, k) => k + 1)
      .filter(i => i && i >= left && i < right);

    if (result.length > 1) {
      // Add first page and dots
      if (result[0] > 1) {
        if (result[0] >= 2) {
          result.unshift('...')
        }else {
        result.unshift(1)}
      }
      // Add dots and last page
      if (result[result.length - 1] < pageCount) {
        if (result[result.length - 1] !== pageCount - 1) {
          result.push('...')
        }
        result.push(pageCount)
      }
    }
    return result;
  }

  // onClickPage(pageNumber: number): void {  
  //    this.pages = this.getArrayOfPage(this.getPageCount(),pageNumber);
  //     if (pageNumber >= 1 && pageNumber <= this.pages.length) {  
  //         this.activePage = pageNumber;  
  //         this.onPageChange.emit(this.activePage);  
  //     }  
  // }
  pageNo: number = 1;
  totalPages = this.totalRecords / this.recordsPerPage;
  first() {
    if (this.pageNo > 1) {
      this.setPage(1);
    }
  }
  prev() {
    this.setPage(Math.max(1, this.pageNo - 1));
  }

  next() {
    this.pages = this.getArrayOfPage(this.getPageCount(), this.pageNo + 1);
    this.setPage(Math.min(this.totalPages, this.pageNo + 1));
  }

  last() {
    if (this.pageNo != this.getPageCount()) {
      this.setPage(this.getPageCount());
    }
  }

  public setPage(val: number) {
    if (val <= 1) {
      this.leftArrowDisable = true;
      this.rightArrowDisable = false;
    } else if (val >= this.getPageCount()) {
      this.leftArrowDisable = false;
      this.rightArrowDisable = true;
    } else {
      this.leftArrowDisable = false;
      this.rightArrowDisable = false;
    }
    if (val >= 1 && val <= this.getPageCount()) {
      if (val.toString() == "...") {
        this.pageNo = this.activePage + 1;
      } else {
        this.pageNo = val;
      }
      this.activePage = this.pageNo;
      this.onPageChange.emit(this.pageNo);
      const pageCount = this.getPageCount();
      this.pages = this.getArrayOfPage(pageCount, this.activePage);
    }
  }

  get isFirst(): boolean {
    return this.pageNo === 1;
  }

  get isLast(): boolean {
    return this.pageNo === this.getPageCount();
  }

} 
