import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { ADAuthService } from './../../KMLWeb_Shared/services/adauth.service';
import { CommonService } from './../../KMLWeb_Shared/services/common.service';
import { UserDetail } from 'src/app/KMLWeb_Shared/models/UserDetail';
import { Role } from 'src/app/KMLWeb_Shared/models/SetSelection';
import { APIHttpService } from './../../KMLWeb_Shared/services/APIHttp.service';
import { Store } from '@ngrx/store';
import { setgraphAccessToken, setmasterroles } from 'src/app/store/counter.actions';
import { NgrxService } from 'src/app/KMLWeb_Shared/services/ngrx.service';

@Injectable({
  providedIn: 'root'
})
export class AdminServiceService {
  AccessToken;
  KMLWebAPIBaseURL;
  SetVersionID;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })


  };

  ImporthttpOptions = {

    headers: new HttpHeaders({
      //'Authorization': 'Bearer ' + this.AccessToken,
      // SetVersionID: this.SetVersionID.toString()
    })
  };
  constructor(private http: HttpClient, private adAuthService: ADAuthService, public commService: CommonService, public apiHttpService: APIHttpService, private ngrxService: NgrxService) {
    this.KMLWebAPIBaseURL = commService.getApiURL();
  }

  public getAccessToken() {
    this.AccessToken = this.adAuthService.GetAccessToken();
    this.SetVersionID = this.commService.getSetVersionID();
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.AccessToken
      })
    };
    this.ImporthttpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.AccessToken,
        SetVersionID: this.SetVersionID.toString()
      })
    };
  }

  public GetUserList(status: any): Observable<any> {
    this.getAccessToken();
    return this.apiHttpService.getAPIHttp('admin/user/list/' + status, this.httpOptions.headers);

  }
  public GetAllUsers(searchValue, skipData, takeData): Observable<any> {
    this.getAccessToken();
    return this.apiHttpService.getAPIHttp('admin/userswithRole?searchText=' + searchValue + '&skipData=' + skipData + '&' + 'takeData=' + takeData, this.httpOptions.headers);
  }

  public DeleteUser(id: number): Observable<any> {
    this.getAccessToken();
    return this.apiHttpService.deleteAPIHttp('admin/user/' + id, this.httpOptions);
  }

  public getinfodetails(id: any): Observable<any> {
    this.getAccessToken();
    return this.apiHttpService.getAPIHttp("admin/user/" + id, this.httpOptions.headers);
  }

  public getSearchdetails(searchinput: any, status: number): Observable<any> {
    this.getAccessToken();
    return this.apiHttpService.getAPIHttp("admin/user/find?searchText=" + searchinput + "&userState=" + status, this.httpOptions.headers);
  }

  public getUserbyid(id: number): Observable<any> {
    this.getAccessToken();
    return this.apiHttpService.getAPIHttp("admin/user/" + id, this.httpOptions.headers);
  }

  public getCountryRoles(): Observable<any> {
    this.getAccessToken();
    return this.apiHttpService.getAPIHttp("admin/lookupmaster", this.httpOptions.headers);
  }


  public IsEmailValid(email: string, alias: string, ID: number): Observable<any> {
    this.getAccessToken();
    return this.apiHttpService.postAPIHttp("admin/user/exists?", { 'Email': email, 'Alias': alias, 'ID': ID }, this.httpOptions.headers);
  }

  public IsUserDeleted(email: string, alias: string, ID: number): Observable<any> {
    this.getAccessToken();
    return this.apiHttpService.postAPIHttp("admin/isUserDeleted?", { 'Email': email, 'Alias': alias, 'ID': ID }, this.httpOptions.headers);

  }

  public getGraphTokenForADSearch(): Observable<any> {
    this.getAccessToken();
    console.log(this.getAccessToken());
    return new Observable(observer => {
      this.adAuthService.getGraphToken().subscribe(data => {
        if (!data) {
          console.log('GraphAccessToken1 No data');
        } else {
          console.log(data + 'This is graphAccessToken');
          this.ngrxService.setgraphAccessToken(data)

          observer.next(data);
          observer.complete();
        }
      }, rejected => {
      });
    });
  }

  public ADSearch(searchInput): Observable<any> {
    /* Attach Graph Token */
    let gatValue: any;
    gatValue = this.ngrxService.getNgrxStoreVal();
    let graphToken: any;
    if (gatValue.graphAccessToken) {
      graphToken = gatValue.graphAccessToken.accessToken;
    } else {
      graphToken = "";
    }
    if (graphToken != undefined && graphToken != null && graphToken != '') {
      this.httpOptions.headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + gatValue.msalidtoken,
        'GraphAuthorization': graphToken,
        SetVersionID: this.SetVersionID.toString()
      })
    }
    var data = { "SearchText": searchInput }
    /* Attach Graph Token end */
    console.log('AD Search Http Options' + this.httpOptions);
    /*return this.http.post(this.KMLWebAPIBaseURL + "admin/ad/search",data , this.httpOptions).map((response: any) => {
      console.log('AD Search Response' + response);
      return response;
    });*/
    return this.apiHttpService.postAPIHttp("admin/ad/search", data, this.httpOptions.headers);
  }

  public AddUserDetails(data): Observable<any> {
    this.getAccessToken();
    /*return this.http.post(this.KMLWebAPIBaseURL + "admin/user/", data, this.httpOptions).map((response: any) => {
      return response;
    });*/
    return this.apiHttpService.postAPIHttp("admin/user/", data, this.httpOptions.headers);
  }

  public UpdateUserDetails(data): Observable<any> {
    this.getAccessToken();
    return this.apiHttpService.putAPIHttp("admin/user/", data, this.httpOptions.headers);
    /*return this.http.put(this.KMLWebAPIBaseURL + "admin/user/", data, this.httpOptions).map((response: any) => {
      return response;
    });*/
  }

  public AdminExport(AdminExportModel): Observable<any> {
    JSON.stringify(AdminExportModel);
    /*return this.http.post(this.KMLWebAPIBaseURL + 'admin/Export', AdminExportModel, this.ImporthttpOptions);*/
    return this.apiHttpService.postAPIHttp('admin/Export', AdminExportModel, this.ImporthttpOptions.headers);
  }

  public AdminFileList(AdminExportModel): Observable<any> {
    /*return this.http.get(this.KMLWebAPIBaseURL + 'admin/GetExportFileDetails', {
      headers: this.httpOptions.headers,
      params: {
        countryId: AdminExportModel.countryId,
        countryLocale: AdminExportModel.countryLocale,
        setVersionID: AdminExportModel.setVersionID,
        searchText: AdminExportModel.searchText,
        userState: AdminExportModel.userState
      }
    });*/
    return this.apiHttpService.getAPIHttpParams('admin/GetExportFileDetails', {
      countryId: AdminExportModel.countryId,
      countryLocale: AdminExportModel.countryLocale,
      setVersionID: AdminExportModel.setVersionID,
      searchText: AdminExportModel.searchText,
      userState: AdminExportModel.userState
    }, this.httpOptions.headers);
  }

  public DownloadFile(id): Observable<any> {
    this.getAccessToken();
    /*return this.http.get(this.KMLWebAPIBaseURL + 'admin/download?ID=' + id, { headers: this.httpOptions.headers, responseType: 'blob' });
    */
    return this.apiHttpService.getAPIHttpBlob('admin/download?ID=' + id, this.httpOptions.headers);
  }

  public ExportFileDetails(id: number): Observable<any> {
    /*return this.http.delete(this.KMLWebAPIBaseURL + 'admin/ExportFileDetails/' + id, this.httpOptions);*/
    return this.apiHttpService.deleteAPIHttp('admin/ExportFileDetails/' + id, this.httpOptions.headers);
  }
  public AllDetails(): Observable<any> {
    this.getAccessToken();
    /*return this.http.get(this.KMLWebAPIBaseURL + "job").map((response: any) => {
      return response;
    });*/
    return this.apiHttpService.getAPIHttpBlob("job", this.httpOptions.headers);
  }

  public payload(): Observable<any> {
    this.getAccessToken();
    /*return this.http.get(this.KMLWebAPIBaseURL + "job").map((response: any) => {
      return response;
    });*/
    return this.apiHttpService.getAPIHttpBlob("job", this.httpOptions.headers);
  }

  public getActivityDetails(GuidID: any): Observable<any> {
    this.getAccessToken();
    /*return this.http.get(this.KMLWebAPIBaseURL + "job/messages/" + GuidID, this.httpOptions).map((response: any) => {
      return response;
    });*/
    return this.apiHttpService.getAPIHttpBlob("job/messages/" + GuidID, this.httpOptions.headers);
  }


  public getLockDetails(): Observable<any> {
    this.getAccessToken();
    /*return this.http.get(this.KMLWebAPIBaseURL + 'admin/getsetcountrylock', this.httpOptions).map((response: any) => {
      return response;
    });*/
    return this.apiHttpService.getAPIHttpBlob("job", this.httpOptions.headers);
  }

  public saveLockDetail(lockPreference): Observable<any> {
    this.getAccessToken();
    JSON.stringify(lockPreference);
    /*return this.http.post(this.KMLWebAPIBaseURL + 'Admin/setcountrylock', lockPreference, this.httpOptions);*/
    return this.apiHttpService.postAPIHttp('Admin/setcountrylock', lockPreference, this.httpOptions.headers);
  }

  public GetCurrentUserwithRole(): Observable<UserDetail> {
    this.getAccessToken();
    /*return this.http.get(this.KMLWebAPIBaseURL + 'admin/GetCurrentUserwithRole', this.httpOptions).map((response: UserDetail) => {
      return response;
    });*/
    return this.apiHttpService.getAPIHttp('admin/GetCurrentUserwithRole', this.httpOptions.headers);
  }


  public GetRoles(): Observable<Role[]> {
    let ngrxstoreVal: any;
    ngrxstoreVal = this.ngrxService.getNgrxStoreVal();
    if (ngrxstoreVal.masterroles == '') {
      this.getAccessToken();
      return this.apiHttpService.getAPIHttp('admin/roles', this.httpOptions.headers).map((response: Role[]) => {
        //localStorage.setItem('masterroles', JSON.stringify(response));
        this.ngrxService.setmasterroles(JSON.stringify(response))
        return response;
      });
    } else {
      return of(JSON.parse(ngrxstoreVal.masterroles));
    }
  }

  public UpdateLastLogin(): Observable<any> {
    this.getAccessToken();
    return this.apiHttpService.putAPIHttp('admin/lastlogin', undefined, this.httpOptions.headers).map((response: any) => {
      return response;
    });
  }

  //23April2021
  GetTotalRecordsFormDB(searchText): Observable<any> {
    return this.apiHttpService.getAPIHttp('Admin/usersTotalRecordCount?searchText=' + searchText, this.httpOptions.headers);
  }
}
