import { state } from '@angular/animations';
import { createReducer, on } from '@ngrx/store';
import { increment, decrement, reset, setCKDStructure, setCVV, setCVVForFilter, setCVVForFilterCount, setCKDContentData, setCVVForTaxonomySync, setStandardCurrentData, setSelectedVersionYear } from './counter.actions';

export const initialState = {
  structure: null,
  CVV: null,
  CVVForFilter: null,
  ckdContent: null,
  CVVForTaxonomySync:null,
  StandardCurrentData: null,
  SelectedVersionYear: null
}

const _ckdCounterReducer = createReducer(
  initialState,
  on(setCKDStructure, (state, { payload }) => ({ ...state, structure: payload.value })),
  on(setCVV, (state, { payload }) => ({ ...state, CVV: payload.value })),
  on(setCVVForFilter, (state, { payload }) => ({ ...state, CVVForFilter: payload.value })),
  on(setCVVForFilterCount, (state, { payload }) => ({ ...state, CVVForFilterCount: payload.value })),
  on(setCKDContentData, (state, { payload }) => ({ ...state, ckdContent: payload.value })),
  on(setCVVForTaxonomySync, (state, { payload }) => ({ ...state, CVVForTaxonomySync: payload.value })),
  on(setStandardCurrentData, (state, { payload }) => ({ ...state, StandardCurrentData: payload.value })),
  on(setSelectedVersionYear, (state, { payload }) => ({ ...state, SelectedVersionYear: payload.value })),
  on(reset, (state) => state)
); 

export function ckdCounterReducer(state, action) {
  return _ckdCounterReducer(state, action);
}