import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AdminServiceService } from 'src/app/KMLWeb_Main/admin/admin.service';
import { ADAuthService } from '../../services/adauth.service';
import { CommonService } from '../../services/common.service';
import { EventEmitterService } from '../../services/event-emitter.service';
import { NgrxService } from '../../services/ngrx.service';


@Component({
  selector: 'app1-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  isAdmin: boolean;
  showHomeMenu = false;
  enableDelete = false;
  enableView = false;
  key: string;
  parentFeatureId: string;
  router: any;
  userRoles: any[] = [];
  userAssignRole: any[] = [];
  appDisable = false;
  subscription: Subscription;
  userLoggedIn = false;
  //userLoggedIn = false;
  constructor(public commonService: CommonService, public adAuthService: ADAuthService, private adminservice: AdminServiceService, private eventEmitterService: EventEmitterService, private ngrxService: NgrxService) {
    if (this.subscription == undefined) {
      this.subscription = this.eventEmitterService.invokeSidebar.subscribe(() => {
        this.checkUserIsLoggedIn();
        this.getPermission();
      })
    }
  }

  ngOnInit() {
    if (sessionStorage.getItem('msal.idtoken')) {
      let ngrxstoreVal: any;
      ngrxstoreVal = this.ngrxService.getNgrxStoreVal();
      if (ngrxstoreVal.currentUser) {
        this.userLoggedIn = true
      } else {
        this.userLoggedIn = false
      }
    }
    this.commonService.userRoles();
  }

  checkUserIsLoggedIn() {
    let ngrxstoreVal: any;
    ngrxstoreVal = this.ngrxService.getNgrxStoreVal();
    this.adAuthService.getCurrentUserInfo();
    var currentUser = JSON.parse(ngrxstoreVal.currentUser);
    this.userLoggedIn = currentUser != null;
  }

  getPermission() {
    let ngrxstoreVal: any;
    ngrxstoreVal = this.ngrxService.getNgrxStoreVal();
    this.userAssignRole = JSON.parse(ngrxstoreVal.userAssignRole);
    if (this.userAssignRole.length > 0) {
      if (this.userAssignRole.indexOf("BusinessAdmin") > -1) {
        console.log("User have all permission.")
        this.appDisable = false;
      } else if (this.userAssignRole.indexOf("SupportUser") > -1) {
        this.appDisable = false;
      } else {
        this.appDisable = true;
      }
    } else {
      this.router.navigateByUrl("/unauthorized")
    }
  }

  NavigateToModule(ModuleName: string,) {
    if (ModuleName === 'home') {
      sessionStorage.removeItem('CurrentRoute');
    }
  }

  GoActivity() {
    this.router.navigate(['/activity']);
  }
}
