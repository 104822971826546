import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { ADAuthService } from './adauth.service';
import { CommonService } from './common.service';

@Injectable({
    providedIn: 'root'
})

export class APIHttpService {
    AccessToken;
    KMLWebAPIBaseURL;
    SetVersionID;
    ImporthttpOptions;
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };
    constructor(private http: HttpClient, private adAuthService: ADAuthService, public commService: CommonService) {
        this.KMLWebAPIBaseURL = commService.getApiURL();
    }

    public getAccessToken() {
        this.AccessToken = this.adAuthService.GetAccessToken();
        this.SetVersionID = this.commService.getSetVersionID();
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.AccessToken
            })
        };
        this.ImporthttpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this.AccessToken,
                SetVersionID: this.SetVersionID.toString()
            })
        };
    }

    //Utility Methods Starts here vvv

    public getAPIHttp(apiAddress: string, headers: any): Observable<any> {
        return this.http.get(this.KMLWebAPIBaseURL + apiAddress, {
            headers: headers
        }).map((response: any) => {
            return response;
        });
    }
    public getAPIHttpforBlob(apiAddress: string, headers: any): Observable<any> {
        return this.http.get(this.KMLWebAPIBaseURL + apiAddress, {
            headers: headers,
            responseType: 'blob'
        })
    }

    public getAPIHttpParams(apiAddress: string, params: any, headers: any): Observable<any> {
        return this.http.get(this.KMLWebAPIBaseURL + apiAddress, {
            headers: headers,
            params: params
        }).map((response: any) => {
            return response;
        });
    }

    public getAPIHttpBlob(apiAddress: string, headers: any): Observable<any> {
        return this.http.get(this.KMLWebAPIBaseURL + apiAddress, {
            headers: headers
        }).map((response: Blob) => {
            return response;
        });
    }

    public postAPIHttp(apiAddress: string, params: any, headers: any): Observable<any> {
        return this.http.post(this.KMLWebAPIBaseURL + apiAddress, params, {
            headers: headers,
            //params: params
        }).map((response: any) => {
            return response;
        });
    }

    public deleteAPIHttp(apiAddress: string, httpOption: any): Observable<any> {
        return this.http.delete(this.KMLWebAPIBaseURL + apiAddress, httpOption);
    }

    public putAPIHttp(apiAddress: string, params: any, headers: any): Observable<any> {
        return this.http.put(this.KMLWebAPIBaseURL + apiAddress, params, {
            headers: headers,
            //params: params
        }).map((response: any) => {
            return response;
        });
    }

}