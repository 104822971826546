// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alert-success,
.alert-info,
.alert-warning,
.alert-danger {
  position: absolute;
  z-index : 9999;
  right   : 10px;
  bottom  : 10px;
}

.alert-icons {
  display: none;
}

.alert-success {
  box-shadow   : 0px 0px 1px 0px #60bd876b;
  border-radius: 5px;
  z-index : 99999999;
}

.alert-success .success-icon,
.alert-success .title-4 {
  display: block !important;
}

.alert-success .title,
.alert-success .icon-area i {
  color: #60BD87;
}

.alert-info {
  box-shadow   : 0px 0px 1px 0px #62b8e775;
  border-radius: 5px;
}

.alert-info .info-icon,
.alert-info .title-2 {
  display: block !important;
}

.alert-info .title,
.alert-info .icon-area i {
  color: #62B8E7;
}

.alert-warning {
  box-shadow   : 0px 0px 1px 0px #efc95c7d;
  border-radius: 5px;
}

.alert-warning .warning-icon,
.alert-warning .title-1 {
  display: block !important;
}

.alert-warning .title,
.alert-warning .icon-area i {
  color: #EFC95C;
}

.alert-danger {
  box-shadow   : 0px 0px 1px 0px #d1728c61;
  border-radius: 5px;
}

.alert-danger .error-icon,
.alert-danger .title-3 {
  display: block !important;
}

.alert-danger .title,
.alert-danger .icon-area i {
  color: #D1728C;
}

.alert {
  width: 249px;
}

.icon-area {
  position               : relative;
  border-top-right-radius: 5px;
  border-top-left-radius : 5px;
  display                : block;
  width: 100px;
}

.icon-area i {
  position : absolute;
  font-size: 49px;
  left     : 50%;
  top      : 0px;
}

.msg-area {
  text-align                : center;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius : 4px;
  overflow-y                : auto;
  overflow-x                : hidden;
  display                   : block;
}

.icon-area .title {
  font-size  : 14px;
  text-align : left;
  font-weight: bold;
  display    : none;
  margin     : 0px;
  font-family: Calibri;
  position   : relative;
  top        : 7px;
  color      : #333333
}

.msg-area .text {
  margin            : 5px 0px;
  text-align        : left;
  color             : #333333;
  display           : -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-family       : Calibri;
  font-size         : 16px;
  font-weight       : normal;
}

.alert-wrapper {
  position  : fixed;
  visibility: hidden;
}

.active-alert {
  position  : fixed;
  visibility: visible;
}

.alertCenter {
  top          : 0px;
  bottom       : auto;
  width        : 100%;
  left         : 0;
  padding      : 5px !important;
  border-radius: 0px;
  padding-left : 35px !important;
}

.WarningSpecial .msg-area {
  overflow-y: auto;
  overflow-x: hidden;
}

.WarningSpecial button {
  position     : absolute;
  top          : 72px;
  right        : 31px;
  border-radius: 123px;
  width        : 35px;
  height       : 35px;
  font-size    : 19px;
  border       : 1px solid #efc95c;
  background   : #fff;
}

.WarningSpecial .msg-area .text {
  text-align        : left;
  color             : #333;
  -webkit-line-clamp: unset !important;
}

.alert.alert-success::before {
  content      : "\\f00c";
  font         : normal normal normal 14px/1 FontAwesome;
  padding-right: 10px;
  color        : #ffffff;
  position     : absolute;
  left         : 10px;
  top          : 15px;
  width        : 16px;
  height       : 16px;
  font-size    : 10px;
  background   : #43b02a;
  border-radius: 50%;
  text-align   : center;
  padding-left : 3px;
  padding-top  : 3px;
}

.alert.alert-success {
  background-color: #edf7eb !important;
}

.alert.alert-danger {
  background-color: #f9e8ed !important;
}

.alert.alert-danger::before {
  content      : "\\f057";
  font         : normal normal normal 14px/1 FontAwesome;
  padding-right: 10px;
  color        : #bc204b;
  position     : absolute;
  left         : 10px;
  font-size    : 18px;
  top          : 14px;
}

.alert.alert-info {
  background-color: #e8f5fb !important;
}

.alert.alert-info::before {
  content      : "\\f05a";
  font         : normal normal normal 14px/1 FontAwesome;
  padding-right: 10px;
  color        : #0091da;
  position     : absolute;
  left         : 10px;
  font-size    : 18px;
  top          : 14px;
}

.alert.alert-warning {
  background-color: #fdf8e7 !important;
}

.alert.alert-warning::before {
  content      : "\\f071";
  font         : normal normal normal 14px/1 FontAwesome;
  padding-right: 10px;
  color        : #eaaa00;
  position     : absolute;
  left         : 10px;
  font-size    : 18px;
  top          : 14px;
}

.alertCloseBtn {
  float: right;
  right: 20px;
  top: 10px;
  color: #bc204b;
  cursor: pointer;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
