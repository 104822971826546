import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './components/header/header.component';
import { BreadcrumbsComponent } from '../KMLWeb_Shared/components/breadcrumbs/breadcrumbs.component';
import { ModalComponent } from '../KMLWeb_Shared/components/modal-popup/modal.component';
import { AlertComponent } from './components/alert-loader/alert/alert.component';
import { ConfirmComponent } from './components/alert-loader/confirm/confirm.component';
import { RouterModule } from '@angular/router';
import { LoaderComponent } from './components/alert-loader/loader/loader.component';
import { UnderConstructionComponent } from './components/under-construction/under-construction.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { UnsupportedComponent } from './components/unsupported/unsupported.component';
import { NgxPrintModule } from 'ngx-print';
import { HotTableModule } from '@handsontable/angular';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { PaginationComponent } from './components/pagination/pagination.component';


@NgModule({
  declarations: [
    HeaderComponent,
    ModalComponent,
    BreadcrumbsComponent,
    AlertComponent,
    ConfirmComponent,
    LoaderComponent,
    UnderConstructionComponent,
    UnauthorizedComponent,
    UnsupportedComponent,
    ClickOutsideDirective,
    PaginationComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgxPrintModule,
    HotTableModule
  ],
  exports: [
    HeaderComponent,
    ModalComponent,
    BreadcrumbsComponent,
    LoaderComponent,
    AlertComponent,
    ClickOutsideDirective,
    PaginationComponent
  ]
})
export class SharedModule { }
