import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { SidebarComponent } from './KMLWeb_Shared/components/sidebar/sidebar.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { AdminModule } from './KMLWeb_Main/admin/admin.module';
// import { AdminRoutingModule } from './KMLWeb_Main/admin/admin-routing.module';
// import { ReportingModule } from './KMLWeb_Main/reporting/reporting.module';
// import { ReportRoutingModule } from './KMLWeb_Main/reporting/report-routing.module';
import { SharedModule } from './KMLWeb_Shared/shared-module';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {  HotTableModule } from '@handsontable/angular';
//import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { DashboardComponent } from './KMLWeb_Main/dashboard/dashboard/dashboard.component';
import { ConfigService } from './config.service';
import { RouterModule } from '@angular/router';
import { LoaderService } from './KMLWeb_Shared/components/alert-loader/loader.service';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MetaReducer, StoreModule, ActionReducer } from '@ngrx/store';
import { environment } from './../environments/environment';
import { ADAuthService } from './KMLWeb_Shared/services/adauth.service';


// export const protectedResourceMap: [string, string[]][] = [
//   ['https://graph.microsoft.com/v1.0/me', ['user.read']]
// ];

import { ApplicationInsightsService } from './KMLWeb_Shared/services/application-insights.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { AuthInterceptor } from './KMLWeb_Shared/interceptor/AuthInterceptor';
import { ErrorHandlingModule } from './error-handling/error-handling.module';
import { DashboardComponent } from './KMLWeb_Main/dashboard/dashboard/dashboard.component';
import { DatePipe } from '@angular/common';
import { ExcelService } from './KMLWeb_Shared/services/excel.service';
import { counterReducer } from './store/counter.reducer';
import { ckdCounterReducer } from './store/counter.ckd.reducer';
import { localStorageSync } from 'ngrx-store-localstorage';
import { NgSelectModule } from '@ng-select/ng-select';
import { metaReducers } from './store/metareducers';
import { FooterComponent } from './KMLWeb_Shared/components/footer/footer.component';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
export function loggerCallback(logLevel, message, piiEnabled) {
  console.log('client logging' + message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      // clientId: '6226576d-37e9-49eb-b201-ec1eeb0029b6', // Prod enviroment. Uncomment to use. 
      clientId: environment.ClientID, // PPE testing environment
      // authority: 'https://login.microsoftonline.com/common', // Prod environment. Uncomment to use.
      authority: environment.AuthorityURL, // PPE testing environment.
      redirectUri: environment.APPUrl,
      postLogoutRedirectUri: environment.APPUrl
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
      //storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  // protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']); // Prod environment. Uncomment to use.
  protectedResourceMap.set('https://graph.microsoft-ppe.com/v1.0/me', ['user.read']);

  return {
    interactionType: InteractionType.Popup,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { 
    interactionType: InteractionType.Popup,
    authRequest: {
      scopes: ['user.read']
    },
    loginFailedRoute: '/unauthorized'
  };
}
//const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;
@NgModule({ declarations: [
        AppComponent,
        SidebarComponent,
        DashboardComponent,
        FooterComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        NgSelectModule,
        HotTableModule.forRoot(),
        ErrorHandlingModule, //global error Logging
        AppRoutingModule,
        //AngularFontAwesomeModule,
        SharedModule,
        MsalModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        NgMultiSelectDropDownModule.forRoot(),
        MatTableModule,
        MatCheckboxModule,
        NoopAnimationsModule,
        FormsModule,
        MatSlideToggleModule,
        NoopAnimationsModule,
        StoreModule.forRoot({}),
        MsalModule,
        // MsalModule.forRoot({
        //   auth: {
        //     clientId: environment.ClientID,
        //     authority:  environment.AuthorityURL,
        //     validateAuthority: true,
        //     redirectUri: environment.APPUrl,
        //     postLogoutRedirectUri:environment.APPUrl,
        //     navigateToLoginRequestUrl: true
        //   },
        //   cache: {
        //     cacheLocation: "sessionStorage",
        //     storeAuthStateInCookie: isIE, // set to true for IE 11
        //   },
        // },
        //   {
        //     popUp: false,
        //     consentScopes: [
        //       "user.read",
        //       "openid",
        //       "profile"
        //     ],
        //     unprotectedResources: ["https://www.microsoft.com/en-us/"],
        //     protectedResourceMap,
        //     extraQueryParameters: {}
        //   }
        // )
        ,
        StoreModule.forRoot({ auth_data: counterReducer, ckd_data: ckdCounterReducer }, { metaReducers })], providers: [ConfigService, BnNgIdleService, DatePipe, ExcelService,
        { provide: APP_INITIALIZER, useFactory: (config: ConfigService) => () => config.load(), deps: [ConfigService], multi: true }, LoaderService,
        //ADAuthService, 
        { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
        ApplicationInsightsService,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService, provideHttpClient(withInterceptorsFromDi())] })
export class AppModule { }
