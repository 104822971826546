// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
  list-style-type: none;
  padding        : 0 10px 0 15px;
  margin         : 1px 0;
}

.sidebar li,
.sidebar a {
  color          : #003e79;
  text-decoration: none;
  padding        : 0 0 25px 0;
  font-weight    : 400;
  font-size      : 14px;
  font-family    : 'Calibri';
}

.sidebar li span img {
  vertical-align: middle;
  margin-right  : 7px;
  opacity       : 0.8;
}
/* .Exportbtn {
  background: url(./../../../../assets/images/export.png) #fff no-repeat 4px 3px;
  border: solid #B3B4B5 1px;
  color: #5B5B5B !important;
  width: 85px;
  padding-left: 29px !important;
} */
.Exportbtn {
  background: url('kml-sprite-v4.88c99c81e571876a.png') no-repeat -23px -447px;
    min-width: 40px;
    min-height: 40px;
    display: block;
    top: 0;
    position: relative;
   
}
.Exportbtn:hover {
  background: url('kml-sprite-v4.88c99c81e571876a.png') no-repeat -63px -449px;
  min-width: 40px;
    min-height: 40px;
    display: block;
    top: 0;
    position: relative;
}
.sidebar li:first-of-type {
  text-align    : right;
  padding-bottom: 8px;
}

.icon-sidebar {
  list-style-type: none;
  padding        : 0;
  margin         : 0 0 15px 0;
}

.icon-sidebar .icon {
  text-align : center;
  display    : block;
  margin-left: 0px;
  border-left: 0px solid transparent;
  margin     : 0 !important;
  padding    : 0px;
  height     : 40px !important;
  width      : 40px !important;
  box-sizing : border-box;
}

.icon-sidebar .icon:hover {
  background-color: #4a505c !important;
  transition      : all ease-in-out .3s !important;
  border-left     : 0px solid #003e79;
}

.icon-sidebar .icon a {
  border-radius  : 3px;
  padding        : 0px;
  /* border      : 1px solid #ffffff; */
  color          : #fff ;
  /* background  : #2b2b2b; */
  text-decoration: none;
  /* width       : 25px;
  height         : 25px; */
  display        : table-cell;
  vertical-align : middle;
  font-size      : 14px;

}

.icon-sidebar .icon.active {
  /* background   : var(--white) !important; */
  border-left     : 2px solid #005eb8;
  background-color: #4a505c !important;
  transition      : all ease-in-out .3s !important;
}

.divider {
  border-bottom: 1px solid #fff;
}

.hamburgurMenu {
  background         : url('sprite_lefticon.7ec2f82af0e51996.png');
  min-width          : 40px;
  min-height         : 40px;
  background-position: -420px -10px;
  display            : block;
  top                : 0;
}

span.sidebartext.hamburgurMenu:hover {
  background-position: -460px -10px;
}

.homeIcon {
  background         : url('kml-sprite-v4.88c99c81e571876a.png');
  min-width          : 40px;
  min-height         : 40px;
  display            : block;
  background-position: -23px -23px;
  top                : 0;
  border             : 0;
  cursor             : pointer !important;
}

span.sidebartext.homeIcon:hover {
  background-position: -63px -23px;
}

.reportIcon {
  background         : url('sprite_lefticon.7ec2f82af0e51996.png');
  min-width          : 40px;
  min-height         : 40px;
  background-position: -290px -190px;
  top                : 0;
  display            : block;
}

span.sidebartext.reportIcon:hover {
  background-position: -330px -190px;
}

.customizeIcon {
  background         : url('kml-sprite-v4.88c99c81e571876a.png');
  min-width          : 40px;
  min-height         : 40px;
  background-position: -23px -231px;
  display            : block;
  top                : 0;
}

span.sidebartext.customizeIcon:hover {
  background-position: -63px -231px;

}

span.sidebartext.customizeIcon.active-link {
  background-position: -103px -231px;
}

.loginIcon {
  background         : url('kml-sprite-v4.88c99c81e571876a.png');
  min-width          : 40px;
  min-height         : 40px;
  background-position: -23px -337px;
  display            : block;
  top                : 0;
}

span.sidebartext.loginIcon:hover {
  background-position: -63px -337px;
}

span.sidebartext.loginIcon.active-link {
  background-position: -103px -337px;
}

.TranslationIcon {
  background         : url('kml-sprite-v4.88c99c81e571876a.png');
  min-width          : 40px;
  min-height         : 40px;
  background-position: -23px -127px;
  display            : block;
  top                : 0;
}

span.sidebartext.TranslationIcon:hover {
  background-position: -63px -127px;
}

span.sidebartext.TranslationIcon.active-link {
  background-position: -103px -127px;
}

.publishIcon {
  background         : url('kml-sprite-v4.88c99c81e571876a.png');
  min-width          : 40px;
  min-height         : 40px;
  background-position: -23px -283px;
  display            : block;
  top                : 0;
}

span.sidebartext.publishIcon:hover {
  background-position: -63px -283px;
}


span.sidebartext.publishIcon.active-link {
  background-position: -103px -283px;
}


.reviewIcon {
  background         : url('kml-sprite-v4.88c99c81e571876a.png');
  min-width          : 40px;
  min-height         : 40px;
  background-position: -23px -392px;
  display            : block;
  top                : 0;
}

span.sidebartext.reviewIcon:hover {
  background-position: -63px -392px;
}


span.sidebartext.reviewIcon.active-link {
  background-position: -103px -392px;
}

.dropbtn {
  box-shadow: none;
  border    : none;
  cursor    : pointer !important;
}

.dropdown {
  position: relative;
  display : inline-block;
  cursor  : pointer !important;
}

.dropdown-content {
  display : none;
  position: relative;

  min-width: auto;
  color    : #fff;
  z-index  : 1;
}

.dropdown-content a {
  color          : black;
  padding        : 12px 16px;
  text-decoration: none;
  display        : block;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.setting {
  text-align: center;
  width     : 38px;
}

.setting i {
  font-size: 20px;
  color    : #fff;
  cursor   : pointer !important;
}

.dropdown-menu .sub-menu {
  left: 100%;
  position: absolute;
  top: 0;
  visibility: hidden;
  margin-top: -1px;
  transition: 1s ease-in-out;
}

.dropdown-menu li:hover .sub-menu {
  visibility: visible;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

::ng-deep .submenu .dropdown-menu{
  position: absolute;
  top: 100%;
  left: 40px;
  top: -2px;
  z-index: 99999;
  display: none; 
  text-align: left;
  background-color: #333333;
 color: #fff !important;
  border: none !important;
  padding: 0px;
min-width: 83px;
  border-radius: 0px 3px 3px 0px;    box-shadow: none !important;
}
::ng-deep .submenu .dropdown-menu a{
  display: block !important;
  color: #fff !important;
  padding: 10px 10px !important;
  border-radius: 0px 3px 3px 0px !important;

}
::ng-deep .submenu .dropdown-menu a:hover{ cursor: pointer; 
  box-shadow: none;
  background-color: #333333;  border-radius: 0px 3px 3px 0px !important;}

.icon-sidebar .icon a.active .homeIcon {
    background-position: -103px -23px;
}

.icon-sidebar .icon a.active .loginIcon {
    background-position: -103px -337px;
}

.icon-sidebar .icon a.active .Exportbtn {
    background-position: -103px -447px;
}
.DataProcessing {
  background: url('kml-sprite-v4.88c99c81e571876a.png') no-repeat -23px -556px;
  min-width: 40px;
  min-height: 40px;
  display: block;
  top: 0;
  position: relative;
}
.DataProcessing:hover {
  background: url('kml-sprite-v4.88c99c81e571876a.png') no-repeat -63px -556px;
  min-width: 40px;
  min-height: 40px;
  display: block;
  top: 0;
  position: relative;
}
.icon-sidebar .icon a.active .DataProcessing {
  background-position: -103px -556px;
}
.ContentManagement {
  background: url('kml-sprite-v4.88c99c81e571876a.png') no-repeat -23px -503px;
  min-width: 40px;
  min-height: 40px;
  display: block;
  top: 0;
  position: relative;
}
.ContentManagement:hover {
  background: url('kml-sprite-v4.88c99c81e571876a.png') no-repeat -63px -503px;
  min-width: 40px;
  min-height: 40px;
  display: block;
  top: 0;
  position: relative;
}
.icon-sidebar .icon a.active .ContentManagement {
  background-position: -103px -503px;
}
.Reporting {
  background: url('kml-sprite-v4.88c99c81e571876a.png') no-repeat -23px -185px;
  min-width: 40px;
  min-height: 40px;
  display: block;
  top: 0;
  position: relative;
}
.Reporting:hover {
  background: url('kml-sprite-v4.88c99c81e571876a.png') no-repeat -63px -185px;
  min-width: 40px;
  min-height: 40px;
  display: block;
  top: 0;
  position: relative;
}
.icon-sidebar .icon a.active .Reporting {
  background-position: -103px -185px;
}
.disableDiv{
  pointer-events:none;
  /* for "disabled" effect */
  opacity: 0.5;
  background: #CCC;
  cursor: not-allowed;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
