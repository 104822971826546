import { Component, OnInit, SecurityContext } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { CurrentUserRole } from '../../models/user';
import { ActivatedRoute } from '@angular/router';
import { ADAuthService } from '../../services/adauth.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit {
  userPermission: boolean = true;
  unAuthorizedUserMessage: string;
  constructor(private commonservice: CommonService, private adAuthService: ADAuthService, private route: ActivatedRoute, private domSanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.userPermission = true;
    this.commonservice.currentUserRole = new CurrentUserRole();
    this.commonservice.currentUserRole.IsHome = false;
    this.route.queryParams.subscribe(params => {
      this.unAuthorizedUserMessage = params.errorMessage;
      if (params.userUnauthorizationMsgHide) {
        this.userPermission = false;
      } else {
        this.userPermission = true;
      }
    });

    sessionStorage.clear();
    localStorage.clear();
  }

  onLogout() {
    //this.adAuthService.logout();
    window.location.href = this.domSanitizer.sanitize(SecurityContext.URL, window.location.origin);
  }
}
