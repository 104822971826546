// import { Injectable } from '@angular/core';
// import { Observable } from 'rxjs';
// import { environment } from './../../../environments/environment';
// import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { CurrentUserDetails } from '../models/CurrentUserDetails';
// import { DatePipe } from '@angular/common';
// import { LoaderService } from '../../KMLWeb_Shared/components/alert-loader/loader.service';
// import { UserDetail } from '../models/UserDetail';
// import { NgrxService } from './ngrx.service';
// import { BroadcastService, MsalService, } from '@azure/msal-angular';
 import { AuthenticationParameters } from 'msal';

// @Injectable({
//     providedIn: 'root'
// })
// export class ADAuthService {
//     public isIframe: boolean;
//     loggedIn: boolean;

//     constructor(private _datePipe: DatePipe, private alertService: LoaderService, private http: HttpClient, private ngrxService: NgrxService,
//         private broadcastService: BroadcastService, private authService: MsalService) {
//         //console.log(environment.AuthorityURL, ' ', environment.ClientID, ' ', environment.APIUrl);
//         this.isIframe = window !== window.parent && !window.opener;
//         if (this.authService.getAccount()) {
//             this.loggedIn = true;
//         }
//         else {
//             this.loggedIn = false;
//         }
//     }
//     public accessToken = null;
//     public graphAccessToken = null;
//     public user;
//     public isAuthenticated = false;
//     loggedUser: CurrentUserDetails = new CurrentUserDetails();

//     public clientID = environment.ClientID;
//     public authority = environment.AuthorityURL;
//     public redirectUri = environment.APPUrl;
//     public postLogoutRedirectUri = environment.APPUrl;
//     public token: AuthenticationParameters;

//     KMLWebAPIBaseURL = environment.APIUrl;

//     httpOptions = {
//         headers: new HttpHeaders({
//             'Content-Type': 'application/json'
//         })
//     };
//     renewIdTokenConfig = {
//         scopes: [this.clientID]
//     };

//     public getCurrentUserInfo() {
//         const user = this.authService.getAccount();
//         if (user) {
//             this.loggedUser.Email = user.userName;
//             this.loggedUser.FirstName = user.name;
//             this.ngrxService.setcurrentUser(JSON.stringify(this.loggedUser))
//         }
//     }

//     public GetCurrentUserwithRole(): Observable<UserDetail> {
//         return this.http.get(this.KMLWebAPIBaseURL + "/admin/GetCurrentUserwithRole").map((response: UserDetail) => {
//             return response;
//         });
//     }

//     public tokenExpired(token: string) {
//         if (token == null || token.length == 0)
//             return false;
//         const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
//         return (Math.floor((new Date).getTime() / 1000)) >= expiry;
//     }  

//     public GetAccessToken() {
//         let ngrxstoreVal: any; 
//         ngrxstoreVal = this.ngrxService.getNgrxStoreVal(); 
//         if (ngrxstoreVal.msalidtoken !== undefined && ngrxstoreVal.msalidtoken != null) { 
//             if(this.tokenExpired(ngrxstoreVal.msalidtoken)) 
//             { 

//                 this.generateNewToken().subscribe(data => { 
//                     this.ngrxService.setmsalidtoken(data); 
//                     this.accessToken = data; 
//                   }); 
//             } 
//             else 
//             { 
//             this.accessToken = ngrxstoreVal.msalidtoken; 
//             } 
//         } 
//         return this.accessToken; 
//     } 

//     ngOnInit() {

//         this.broadcastService.subscribe("msal:loginFailure", (payload) => {
//             //console.log("login failure " + JSON.stringify(payload));
//             this.loggedIn = false;

//         });

//         this.broadcastService.subscribe("msal:loginSuccess", (payload) => {
//             //console.log("login success " + JSON.stringify(payload));
//             this.loggedIn = true;
//         });
//     }

//     public generateNewToken(): Observable<any> {
//         return new Observable(observer => {
//             this.token = {
//                 scopes: [this.clientID]
//             };
//             this.authService.acquireTokenSilent(this.token)
//                 .then(function (accessTokenResponse) {
//                     if (!accessTokenResponse) {
//                         console.log('Access token is null with a success response');
//                     } else {
//                         observer.next(accessTokenResponse.idToken.rawIdToken);
//                         observer.complete();
//                     }
//                 }).catch(function (error) {
//                     if (error.indexOf('consent_required') !== -1 ||
//                         error.indexOf('interaction_required') !== -1 || error.indexOf('login_required') !== -1) {
//                         observer.complete();
//                     }
//                     console.log(error);
//                 });
//         });
//     }

//     public getRenewIDToken(): Observable<any> {
//         return new Observable(observer => {
//             this.token = {
//                 scopes: [this.clientID]
//             };
//             this.authService.acquireTokenSilent(this.token)
//                 .then(function (accessTokenResponse) {
//                     if (!accessTokenResponse) {
//                         console.log('Access token is null with a success response');
//                     } else {
//                         observer.next(accessTokenResponse.accessToken);
//                         observer.complete();
//                     }
//                 }).catch(function (error) {
//                     if (error.indexOf('consent_required') !== -1 ||
//                         error.indexOf('interaction_required') !== -1 || error.indexOf('login_required') !== -1) {
//                         observer.complete();
//                     }
//                     console.log(error);
//                 });
//         });
//     }

//     public getGraphToken(): Observable<any> {
//         var tenantConfig = {
//             scopes: ['Directory.Read.All']
//         };
//             return new Observable(observer => {
//                 this.authService.acquireTokenSilent(tenantConfig)
//                 .then(function (accessTokenResponse) {
//                     if (!accessTokenResponse) {
//                         console.log('Access token is null with a success response');
//                     } else {
//                         observer.next(accessTokenResponse);
//                         observer.complete();
//                     }
//                 }).catch(function (error) {
//                     if (error.indexOf('consent_required') !== -1 ||
//                         error.indexOf('interaction_required') !== -1 || error.indexOf('login_required') !== -1) {
//                         observer.complete();
//                     }
//                     console.log(error);
//                 });
//             });
        
//     }

//     logout() {
//         // remove user from local & session storage to log user out
//         const SessionValuestobeRemoved = [];
//         localStorage.removeItem('currentUser');

//         for (let i = 0; i < sessionStorage.length; i++) {
//             SessionValuestobeRemoved.push(sessionStorage.key(i));
//         }
//         // tslint:disable-next-line: prefer-for-of
//         for (let i = 0; i < SessionValuestobeRemoved.length; i++) {
//             sessionStorage.removeItem(SessionValuestobeRemoved[i]);
//         }
//         this.authService.logout();
//     }
// }
import { Router } from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from './../../../environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { CurrentUserDetails } from '../models/CurrentUserDetails';
import { DatePipe } from '@angular/common';
import { LoaderService } from '../../KMLWeb_Shared/components/alert-loader/loader.service';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus, PopupRequest } from '@azure/msal-browser';
import { filter, takeUntil } from 'rxjs/operators';
import { NgrxService } from './ngrx.service';

@Injectable({
    providedIn: 'root'
})
export class ADAuthService {
    private readonly _destroying$ = new Subject<void>();
    constructor(private _datePipe: DatePipe, private alertService: LoaderService,
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private authService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        private router: Router, private ngrxService: NgrxService) {
        this.msalBroadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None),
                takeUntil(this._destroying$)
            )
            .subscribe(() => {
                this.setLoginDisplay();
                this.checkAndSetActiveAccount();
            })
    }

    public accessToken = null;
    public graphAccessToken = null;
    public user = null;
    public isAuthenticated = false;
    loggedUser: CurrentUserDetails = new CurrentUserDetails();

    public clientID = environment.ClientID;
    public authority = environment.AuthorityURL;
    public redirectUri = environment.APPUrl;
    public postLogoutRedirectUri = environment.APPUrl;
    public loginDisplay: any;
    sessionkey: any;

    KATAPIBaseURL = environment.APIUrl;

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Cache-Control': 'must-revalidate, max-age=0'
        })
    };

    tenantConfig = {
        scopes: ['Directory.Read.All']
    };

    renewIdTokenConfig = {
        scopes: [this.clientID]
    };

    public setLoginDisplay() {
        this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    }
    public loginPopup() {
        if (this.msalGuardConfig.authRequest) {
            this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
                .subscribe((response: AuthenticationResult) => {
                    this.authService.instance.setActiveAccount(response.account);
                    this.router.navigate(["/"]);
                });
        } else {
            this.authService.loginPopup()
                .subscribe((response: AuthenticationResult) => {
                    this.authService.instance.setActiveAccount(response.account);
                    this.router.navigate(["/"]);
                });
        }
    }
    public checkAndSetActiveAccount() {
        /**
         * If no active account set but there are accounts signed in, sets first account to active account
         * To use active account set here, subscribe to inProgress$ first in your component
         * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
         */
        let activeAccount = this.authService.instance.getActiveAccount();

        if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
            let accounts = this.authService.instance.getAllAccounts();
            this.authService.instance.setActiveAccount(accounts[0]);
            this.user = accounts;
            const accountDetails = accounts[0];
            this.sessionkey = accountDetails.homeAccountId + '-' + accountDetails.environment + '-idtoken-' + accountDetails.idTokenClaims['aud'] + '-' + accountDetails.tenantId + '---';
        }
    }
    public getCurrentUserInfo() {
        //this.clientApplication = new Msal.UserAgentApplication(this.clientID, this.authority, this.authCallback, { storeAuthStateInCookie: false, cacheLocation: 'localStorage' });
        const user = this.authService.instance.getActiveAccount();

        this.loggedUser.Email = user.username;
        this.loggedUser.FirstName = user.name;
        //this.loggedUser = '';
        
        this.ngrxService.setcurrentUser(JSON.stringify(this.loggedUser))
        sessionStorage.setItem('currentUser', JSON.stringify(this.loggedUser));
    }

    public GetAccessToken(): Observable<any> {
        if(this.sessionkey == undefined){
            let activeAccount = this.authService.instance.getActiveAccount();
            if (activeAccount){
                this.sessionkey = activeAccount.homeAccountId + '-' + activeAccount.environment + '-idtoken-' + activeAccount.idTokenClaims['aud'] + '-' + activeAccount.tenantId + '---';
            }
        }

        if (sessionStorage.getItem(this.sessionkey) !== undefined && sessionStorage.getItem(this.sessionkey) != null) {
            this.accessToken = JSON.parse(sessionStorage.getItem(this.sessionkey)).secret;
        }
        this.ngrxService.setmsalidtoken(this.accessToken);
        sessionStorage.setItem('msal.idtoken', JSON.stringify(this.accessToken));
        return this.accessToken;
    }

    

    public generateNewToken(): Observable<any> {
        const accessTokenRequest = {
            scopes: [this.clientID]
        }
        return new Observable(observer => {


            this.authService.acquireTokenSilent({
                account: this.authService.instance.getActiveAccount(), // get the current user's account
                scopes: ["User.Read"],
                forceRefresh: true
            }).subscribe({
                next: (result: AuthenticationResult) => {
                },
                error: (error) => {
                    this.authService.acquireTokenRedirect({
                        scopes: ["User.Read"]
                    });
                }
            });
        });
    }

    public getRenewIDToken(): Observable<any> {
        const user = this.authService.instance.getActiveAccount();
        if (user) {
            // tslint:disable-next-line: deprecation
            return new Observable(observer => {
                this.authService.acquireTokenSilent({
                    account: this.authService.instance.getActiveAccount(), // get the current user's account
                    scopes: ["User.Read"]
                }).subscribe({
                    next: (result: AuthenticationResult) => {
                    },
                    error: (error) => {
                        this.authService.acquireTokenRedirect({
                            scopes: ["User.Read"]
                        });
                    }
                });
            });
        }
    }

    public getGraphToken(): Observable<any> {
        const user = this.authService.instance.getActiveAccount();
        if (user) {
            // tslint:disable-next-line: deprecation
            return new Observable(observer => {
                this.authService.acquireTokenSilent({
                    account: this.authService.instance.getActiveAccount(), // get the current user's account
                    scopes: ["Directory.Read.All"]
                }).subscribe({
                    next: (result: AuthenticationResult) => {
                        observer.next(result);
                        observer.complete();
                    },
                    error: (error) => {
                        this.authService.acquireTokenRedirect({
                            scopes: ["Directory.Read.All"]
                        });
                    }
                });
            });
        }
    }

    public authCallback(errorDesc, token, error, tokenType) {
        // msal object is bound to the window object after the constructor is called.
        if (token) {

        } else {
        }
    }

    logout() {
      // remove user from local & session storage to log user out
      const SessionValuestobeRemoved = [];
      localStorage.removeItem('currentUser');
      // tslint:disable-next-line: no-shadowed-variable
      for (let i = 0; i < sessionStorage.length; i++) {
        SessionValuestobeRemoved.push(sessionStorage.key(i));
      }
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < SessionValuestobeRemoved.length; i++) {
        sessionStorage.removeItem(SessionValuestobeRemoved[i]);
      }   
      this.user = []; 
      this.authService.logoutRedirect();
    }    
    public tokenExpired(token: string) {
                if (token == null || token.length == 0)
                    return false;
                const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
                return (Math.floor((new Date).getTime() / 1000)) >= expiry;
            } 
       

}

