// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-bar {
    height       : 40px;
    width        : calc(100% - 40px);
    padding      : 0;
    margin       : 0;
    display      : flex;
    background   : #fff !important;
    border-bottom: 1px solid #d6d6d6;
    margin-left  : 40px;
    top          : 0;
}

.operational-support-tool {
    font-family   : Calibri;
    font-size     : 16px;
    font-weight   : normal;
    font-stretch  : normal;
    font-style    : normal;
    line-height   : 2.38;
    letter-spacing: 0.44px;
    color         : #005eb8;
    padding-left  : 15px;
}

.header-search {
    background-color: #003F6B;
    height          : 32px;
    width           : 100%;
    border          : 0;
    color           : #C6C6C6;
    margin-right    : 10px;
}

.breadcrumb-container {
    float: left;
}

.breadcrumb {
    display         : flex;
    flex-wrap       : wrap;
    padding         : 0.45rem 1rem !important;
    margin-bottom   : 1rem;
    list-style      : none;
    background-color: #ffffff !important;
    border-radius   : 0.25rem;
    font-family     : Calibri;
    font-size       : 16px;
    font-weight     : normal;
    font-stretch    : normal;
    font-style      : normal;
    line-height     : 1.38;
    letter-spacing  : 0.44px;
    color           : #005eb8;
}

.right-buttons {
    margin-left : auto;
    display     : flex;
    margin-right: 40px;
    ;
}

.right-buttons>div {
    display: flex;
}

.right-buttons>div:not(:last-child) {
    margin-right: 5px;
}

.right-buttons div .settings-button {
    padding     : 7px 13px;
    font-size   : 18px;
    background  : transparent; 
    color       : #005eb8;
    border      : 0;
    cursor      : pointer; 
    border-right: 1px solid #d6d6d6;
}

.dp {
    display        : flex;
    align-items    : center;
    justify-content: center; 
    color          : #747474;
    padding        : 0;
    border-radius  : 50%;
    width          : 34px;
    height         : 34px;
    margin         : 3px 6px;
    font-size      : 29px;
}

.kat-user-name {
    font-size      : 13px;
    color          : #ffffff;
    display        : flex;
    align-items    : center;
    justify-content: center;
    margin-right   : 15px;
    flex-direction : column;
    line-height    : 16px;
}

.kat-user-name span {
    font-size     : 14px;
    color         : #005eb8;
    font-size     : 14px;
    font-weight   : normal;
    font-stretch  : normal;
    font-style    : normal;
    line-height   : 20px;
    letter-spacing: 0.44px;
    font-weight   : 400;
}

.kat-user-name .design {
    font-size   : 11px;
    color       : #9c9c9c;
    margin-right: auto;
}

.preference {
    font-size      : 13px;
    color          : #00338d;
    display        : flex;
    align-items    : center;
    justify-content: center;
    margin-right   : 15px;
    /* flex-direction : column; */
    line-height    : 16px;
    font-family    : Calibri;
}

.disabled {
    display: none;
}

.logout {
    width      : 45px;
    line-height: 40px;
    font-size  : 20px;
    text-align : center;
    color      : #005eb8;
}

.logout i {
    transition        : all .2s ease-in-out;
    cursor            : pointer;
}

.logout i:hover {
    color            : red;
    transform        : scale(1.4);
}

.breadcrumb-container.border-left::before {
    border-left: 1px solid #dee2e6 !important;
    content    : '';
    position   : absolute;
    z-index    : 999;
    left       : 0;
    top        : 3px;
    width      : 1px;
    height     : 30px;
}

.breadcrumb-container.border-left {
    position   : relative;
    border-left: 0px !important
}

.cog-icon {
    background         : url('sprite.659f7ee90bfafcd9.png');
    width              : 20px;
    height             : 20px;
    display            : inline-block;
    background-position: 26px -4px;
    margin-top         : 2px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
